.banner {
	overflow: hidden;
	width: 100%;
	display: inline-block;
	position: relative;

	// &--small {
	// 	position: relative;
	// 	z-index: 1;
	// 	font-weight: bold;
	// 	display: flex;
	// 	justify-content: space-between;
	// 	width: 100%;
	// 	letter-spacing: 0.05em;
	// 	@include vw(font-size, 25px);
	// 	@include vw(padding, 40px);

	// 	@include desktop() {
	// 		@include vw(font-size, 15px);
	// 		@include vw(padding, 40px);

	// 		@media (max-height: 700px) {
	// 			@include vw(padding, 20px);
	// 		}
	// 	}
	// }

	&--large {
		display: inline-block;
		position: relative;
		z-index: 1;
		width: 100%;
		overflow: hidden;
		letter-spacing: 0.05em;
		font-weight: bold;
		@include vw(font-size, 500px);

		@include desktop() {
			@include vw(font-size, 350px);

			@media (max-height: 700px) {
				@include vw(font-size, 280px);
			}
		}
	}

	&__loop {
		white-space: nowrap;
		display: inline-block;
		position: relative;
		// transform: translateX(-200%);
		// animation-name: translate-banner-loop;
		// animation-duration: 1900s;
		// animation-timing-function: linear;
		// animation-iteration-count: infinite;

		li {
			transform: translateZ(0);
		}

		>span {
			&:last-child {
				position: absolute;
				left: 100%;
				margin-left: 0.18em;
			}
		}
	}
}