/*********************
Links
*********************/

a, a:visited {
	color: $white;
	text-decoration: none;

	&:hover {
		transition: color 0s;
		color: $white;
	}

	&:focus {
		outline: none;
	}

	&:active {
		color: $white;
	}
}

/*********************
Headings
*********************/

.h {
	&--1 {
		font-weight: bold;
		letter-spacing: 0.05em;
		line-height: 0.85;
		@include vw(font-size, 250px);

		@include desktop() {
			@include vw(font-size, 300px);
		}
	}
}

.underlined {
	background-image: linear-gradient(to right, $black 0%, $black 100%);
	background-repeat: repeat-x; // make it horizontal
	background-position: 0 100%; // change the second value to adjust how far from the top the underline should be
	background-size: 100% 1px; // change the second value to the desired height of the underline
}