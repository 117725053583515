/*****************************************
Buttons
*****************************************/

.btn {
	user-select: none;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
	text-align: center;
	padding: 0;
	appearance: none;
	border: none;
	outline: none;
	background: transparent;
	font-family: $font;

	&--rect {
		background: $white;
		color: $red;
		font-weight: bold;
		line-height: 0.9;
		padding: 0.1em 0.3em 0;
		@include vw(font-size, 60px);
		letter-spacing: 0.1em;

		@include desktop() {
			@include vw(font-size, 35px);
		}

		// &:hover {
		// 	transition-duration: 0.2s !important;
		// 	background: transparent;
		// 	color: $white !important;
		// }

	// 	&--black {
	// 		color: $white !important;
	// 		background: $black;
	// 		border: 1px solid $black;

	// 		&:hover {
	// 			color: $black !important;
	// 			background: $white;
	// 		}
	// 	}
	}
}