/*****************************************
inputs
*****************************************/

// setting up defaults
.input,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
select,
textarea {
	display: block;
	border: none;
	border-bottom: 2px solid $white;
	// transition: color, background 0.2s ease-out;
	background: transparent;
	font-family: $font;
	letter-spacing: 0.05em;
	outline: none;
	color: $white;
	font-weight: bold;
	appearance: none;
	border-radius: 0;
	padding: 0;

	@include vw(font-size, 60px);
	// width: 60%;
	width: 7.4em;
	text-align: center;

	@include mobile() {
		// @include vw(height, 60px);
	}

	@include desktop() {
		@include vw(font-size, 35px);
		// @include vw(width, 300px);

	}

	&:focus,
	&:active {

	}

	&::placeholder {
		text-transform: uppercase;
		color: $white;
		// letter-spacing: 0.25em;
	}
}