$linear					:		cubic-bezier( 0.250, 0.250, 0.750, 0.750);
$easeInQuad				:		cubic-bezier( 0.26, 0, 0.6, 0.2 );
$easeInCubic			:		cubic-bezier( 0.4, 0, 0.68, 0.06 );
$easeInQuart			:		cubic-bezier( 0.52, 0, 0.74, 0 );
$easeInQuint			:		cubic-bezier( 0.64, 0, 0.78, 0 );
$easeInSine				:		cubic-bezier( 0.32, 0, 0.6, 0.36 );
$easeInExpo				:		cubic-bezier( 0.66, 0, 0.86, 0 );
$easeInCirc				:		cubic-bezier( 0.54, 0, 1, 0.44 );
$easeInBack				:		cubic-bezier( 0.600, -0.280, 0.735, 0.045 );

$easeOutQuad			:		cubic-bezier( 0.4, 0.8, 0.74, 1 );
$easeOutCubic			:		cubic-bezier( 0.34, 1.02, 0.68, 1 );
$easeOutQuart			:		cubic-bezier( 0.26, 1.04, 0.54, 1 );
$easeOutQuint			:		cubic-bezier( 0.22, 1.1, 0.48, 1 );
$easeOutSine			:		cubic-bezier( 0.4, 0.64, 0.68, 1 );
$easeOutExpo			:		cubic-bezier( 0.16, 1.08, 0.38, 0.98 );
$easeOutCirc			:		cubic-bezier( 0, 0.56, 0.46, 1 );
$easeOutBack			:		cubic-bezier( 0.175, 0.885, 0.320, 1.275 );

$easeInOutQuad			:		cubic-bezier( 0.48, 0.04, 0.52, 0.96 );
$easeInOutCubic			:		cubic-bezier( 0.66, 0, 0.34, 1 );
$easeInOutQuart			:		cubic-bezier( 0.770, 0.000, 0.175, 1.000 );
$easeInOutQuint			:		cubic-bezier( 0.84, 0, 0.16, 1 );
$easeInOutSine			:		cubic-bezier( 0.36, 0, 0.64, 1 );
$easeInOutExpo			:		cubic-bezier( 0.9, 0, 0.1, 1 );
$easeInOutCirc			:		cubic-bezier( 0.88, 0.14, 0.12, 0.86 );
$easeInOutBack			:		cubic-bezier( 0.175, 0.885, 0.320, 1.275 );

$easeBounceOut			:		cubic-bezier (0.175, 0.885, 0.320, 1.275 );
