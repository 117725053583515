@keyframes translate-banner-loop {
	0% {transform: translateX(0%);}
	100% {transform: translateX(-100%);}
}

@keyframes translate-grain-loop {
	0% {transform: translate3d(0,0,0);}
	25% {transform: translate3d(0,0,0);}
	25.01% {transform: translate3d(-10%,0,0);}
	50% {transform: translate3d(-10%,0,0);}
	50.01% {transform: translate3d(0,-10%,0);}
	75% {transform: translate3d(0,-10%,0);}
	75.1% {transform: translate3d(-10%,-10%,0);}
	100% {transform: translate3d(-10%,-10%,0);}
}