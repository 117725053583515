.demain {
	&__link {
		-webkit-tap-highlight-color: transparent;
		appearance: none;
		border: none;
		outline: none;
		background: transparent;
		position: relative;
		overflow: hidden;
		z-index: 1;
		display: inline-block;
		user-select: none;
		cursor: pointer;
		line-height: 1.1;
		// padding: 0.5em 0;
		transition: color 0.6s $easeInOutQuart;

		&:hover, &.active {
			&::after {
				transition: transform 0.8s $easeOutExpo, background 0.3s $easeOutQuart;
				transform: translate3d(0%,0,0);
			}
		}

		&--underlined {
			&::after {
				content: '';
				position: absolute;
				left: 0; right: 0;
				bottom: 0;
				display: block;
				height: 2px;
				transform-origin: left center;
				transition: transform 0.8s $easeInOutExpo;
				transform: none;
				background: $black;
			}

			&:hover {
				&::after {
					transform-origin: right center;
					transform: translateZ(0) scaleX(0);
				}
			}

			&--inverted {
				&::after {
					transform-origin: right center;
					transform: translateZ(0) scaleX(0);
				}
				
				&.is-active, &:hover {
					&::after {
						transform-origin: left center;
						transform: none;
					}
				}
			}
		}
	}
}