body, html {
	// height: 100%;
}

body {
	font-family: $font;
	color: $black;
	@include font-smoothing(on);
	overflow: hidden;
	width: 100%;
	height: 100%;
	-webkit-text-size-adjust: none;
	text-transform: uppercase;
}

* {
	-webkit-tap-highlight-color: transparent;
}

.loading * {
	cursor: progress !important;
}

img {
	display: block;
}

.desktop-only {
	@include mobile() {
		display: none;
	}
}