/*********************
COLORS
*********************/

$black:            #171717;
$white:            #FDF3E4;

$blue:             #394496;
$red:              #CB2E26;
$rust:             #8B3813;


/*********************
GRID
*********************/

$columns: 16;
$gutter: 0.58;
$base-line-height: 20px;
$lineheight: $base-line-height;
$global-margin: 44px;
$global-margin-mobile: 22px;


/*********************
RESPONSIVE
*********************/

$mobile-mockup-width: 750px;
$desktop-mockup-width: 1440px;
$mockup-width: $mobile-mockup-width;

$mobile-landscape: 414px;
$tablet: 768px;
$desktop: 769px;
$large-desktop: 1366px;
$small-height: 750px;