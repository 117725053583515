/*****************************************
Utils
*****************************************/

.laliste {
	.f {
		display: flex;

		&--center-center {
			justify-content: center;
			align-items: center;
		}

		&--left-center {
			justify-content: flex-start;
			align-items: center;
		}
	}

	.hidden {
		display: none;
	}
}