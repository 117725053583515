@font-face {
    font-family: 'formula_condensed-web';
    src: url('../fonts/formulacondensed-light-web.woff2') format('woff2'),
         url('../fonts/formulacondensed-light-web.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'formula_condensed-web';
    src: url('../fonts/formulacondensed-bold-web.woff2') format('woff2'),
         url('../fonts/formulacondensed-bold-web.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

// font stacks
$font: "formula_condensed-web", helvetica, sans-serif;