@mixin mobile {
    $tmp: $mockup-width;
    $mockup-width: $mobile-mockup-width !global;
    @media screen and (max-width: $desktop - 1) {
        @content;
    }
    $mockup-width: $tmp !global;
}

@mixin mobile-landscape {
    $tmp: $mockup-width;
    $mockup-width: $mobile-mockup-width !global;
    @media screen and (min-width: $mobile-landscape) {
        @content;
    }
    $mockup-width: $tmp !global;
}

@mixin tablet {
    $tmp: $mockup-width;
    $mockup-width: $mobile-mockup-width !global;
    @media screen and (min-width: $tablet) {
        @content;
    }
    $mockup-width: $tmp !global;
}

@mixin desktop {
    $tmp: $mockup-width;
    $mockup-width: $desktop-mockup-width !global;
    @media screen and (min-width: $desktop) {
        @content;
    }
    $mockup-width: $tmp !global;
}

@mixin desktop-max {
    $tmp: $mockup-width;
    $mockup-width: $desktop-mockup-width !global;
    @media screen and (max-width: $desktop - 1) {
        @content;
    }
    $mockup-width: $tmp !global;
}

@mixin large-desktop {
    $tmp: $mockup-width;
    $mockup-width: $desktop-mockup-width !global;
    @media screen and (min-width: $large-desktop) {
        @content;
    }
    $mockup-width: $tmp !global;
}

@mixin very-large-desktop {
    $tmp: $mockup-width;
    $mockup-width: $desktop-mockup-width !global;
	@media screen and (min-width: $very-large-desktop) {
	    @content;
    }
    $mockup-width: $tmp !global;
}

@mixin small-height {
    $tmp: $mockup-width;
    $mockup-width: $mobile-mockup-width !global;
	@media screen and (max-height: $small-height) {
		@content;
    }
    $mockup-width: $tmp !global;
}