/*****************************************
Grid
*****************************************/

$susy: (
	columns: susy-repeat($columns),
	gutters: $gutter,
	spread: "narrow",
	container-spread: "wider",
	svg-grid-colors: rgba(#71fff7, 0.2)
);

.demain {
	&__container {
		width: 100%;
		margin: 0 auto;
		position: relative;
		max-width: 1680px;
	}

	&__r {
		position: relative;
		overflow: hidden;
		width: 100%;
		display: inline-block;

		// &::after {
		// 	content: "";
		// 	display: block;
		// }

		img {
			position: absolute;
			top: 0; left: 0;
			width: 100%;
		}
	}

	@if variable-exists(debug) {
		.grid {
			background: susy-svg-grid() no-repeat scroll;
		}
	}
}