.home {
	font-family: $font;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;left: 0;

	.h--1 {
		position: relative;
		z-index: 1;
		color: $red;
		text-align: center;

		span {
			transform-origin: top center;
			will-change: transform;
		}
	}

	.t {
		transform-origin: top center;
	}

	.words {
		&>span {
			display: inline-block;
			overflow: hidden;
			padding-top: 0.1em;

			&>span {
				display: block;
				transform: translateY(120%);
			}
		}
	}

	&__header {
		// height: 100%;
		
		picture {
			position: absolute;
			z-index: 0;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform: translateY(-100vh);

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.h--1 {
			span {
				display: inline-block;
				transform: translateY(100vh);
			}
		}
	}

	&__main {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		color: $white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		transform: translateY(100%);
	}

	&__banner {
		position: relative;
		// overflow: hidden;
	}

	&__countdown {
		position: absolute;
		z-index: 2;
		font-weight: bold;
		letter-spacing: 0.1em;
		padding: 0.1em 0.1em 0;
		top: 50%;
		left: 50%;
		margin-top: -0.42em;
		transform: translate(-50%,-50%);
		@include vw(font-size, 60px);
		color: $red;
		white-space: nowrap;
		
		@include desktop() {
			@include vw(font-size, 50px);
		}

		background: $white;
	}

	&__form {
		position: relative;
		z-index: 1;
		text-align: center;
		letter-spacing: 0.1em;
		display: block;
		margin: 0 auto;

		@include desktop() {
			@include vw(width, 700px);
			
			@include small-height() {
				margin-top: -30px;
			}
		}

		.form-row {
			position: relative;
			display: flex;
			justify-content: center;
			
			@include mobile() {
				flex-direction: column;
				align-items: center;
			}

			.btn--rect {
				@include vw(margin-left, 20px);

				@include mobile() {
					@include vw(margin-top, 30px);
				}
				
				@include desktop() {
					@include vw(margin-left, 20px);
				}
			}
		}

		&__confirmation {
			@extend .input;
			white-space: nowrap;
			width: auto;
			position: absolute;
			bottom: 0;
			left: 50%;
			line-height: 1.2;
			transform: translateX(-50%);
			border: none;
			overflow: hidden;
			visibility: hidden;

			@include mobile() {
				bottom: auto;
				top: 0;
				height: auto;
				@include vw(font-size, 36px);
			}

			i {
				content: "";
				width: 100%;
				position: absolute;
				display: block;
				bottom: 0;
				height: 2px;
				background: $white;
				transform: translateX(-100%);
			}
		}

		&__instructions {
			pointer-events: none;
			width: 100%;
			@include vw(margin-top, 25px);
			@include vw(font-size, 18px);
			@include vw(padding-left, 70px);
			@include vw(padding-right, 70px);
			text-align: center;
			display: block;
			line-height: 1.6;
			// opacity: 0;
			
			@include desktop() {
				line-height: 2;
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translateX(-50%);
				padding: 0;
				@include vw(font-size, 13px);
				@include vw(margin-top, 35px);
				
				@include small-height() {
					line-height: 1.4;
				}
			}
		}
	}

	&__background {
		background: $red;
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
		overflow: hidden;

		.grain {
			pointer-events: none;
			display: block;
			animation-name: translate-grain-loop;
			animation-duration: 0.15s;
			animation-timing-function: linear;
			animation-iteration-count: infinite;
			width: 120%;
			height: 120%;
			background: url(../imgs/grain/001.png) repeat;
			// background: url(http://api.thumbr.it/whitenoise-361x370.png?background=00000000&noise=ffffff&density=55&opacity=15) repeat;
		}
	}

	.btn--rect, &__countdown, &__background {
		transition: all 1.2s $easeOutExpo;
	}
	.btn--rect, &__countdown {
		transition-property: color;
	}
	&__background {
		transition-property: background;
	}

	&--red {
		.home__countdown {
			color: $red;
		}
		.home__background {
			background: $red;
		}
	}

	&--rust {
		.btn--rect, .home__countdown {
			color: $rust;
		}
		.home__background {
			background: $rust;
		}
	}

	&--blue {
		.btn--rect, .home__countdown {
			color: $blue;
		}
		.home__background {
			background: $blue;
		}
	}
}